import React from 'react';
import '../styles/SocialLink.css';
import profilePic from '../images/profile-pic.jpeg';

const SocialLink = () => {
    const socialData = {
        name: "Darren Tan",
        location: "Miri, Sarawak, Malaysia",
        description: "Web Developer and Software Engineer",
        links: [
            { name: "Portfolio", url: "https://www.darren2581.me/" },
            { name: "GitHub", url: "https://github.com/darren2581" },
            { name: "LinkedIn", url: "https://www.linkedin.com/in/darren2581/" },
            { name: "Instagram", url: "https://www.instagram.com/darren_2581" },
        ]
    };

    return (
        <div className="container">
            <div className="card">
                <div className="profile-image">
                    <img src={profilePic} alt="Profile" />
                </div>
                <h1 className="name">{socialData.name}</h1>
                <p className="location">{socialData.location}</p>
                <p className="description">"{socialData.description}"</p>

                <div className="links-container">
                    {socialData.links.map((link, index) => (
                        <a
                            key={index}
                            href={link.url}
                            className="social-link"
                        >
                            {link.name}
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SocialLink;