import React from 'react'
import '../styles/Skills.css'
import Cpp from '../images/C++.png'
import Java from '../images/Java.png'
import MySQL from '../images/MySQL.png'
import HTML from '../images/HTML.png'
import CSS from '../images/CSS.png'
import JavaScript from '../images/JS.png'
import ReactJS from '../images/React.png'
import Firebase from '../images/Firebase.png'

const Skills = () => {
    return (
        <div className="skills">
            <div className="skills-width">
                <div className="skills-title">
                    <h2>Technical Skills</h2>
                </div>
                <div className="skills-content">
                    <div className="skills-item">
                        <img src={HTML} alt="HTML" />
                    </div>
                    <div className="skills-item">
                        <img src={CSS} alt="CSS" />
                    </div>
                    <div className="skills-item">
                        <img src={JavaScript} alt="JavaScript" />
                    </div>
                    <div className="skills-item">
                        <img src={ReactJS} alt="ReactJS" />
                    </div>
                    <div className="skills-item">
                        <img src={Firebase} alt="Firebase" />
                    </div>
                    <div className="skills-item">
                        <img src={Cpp} alt="C++" />
                    </div>
                    <div className="skills-item">
                        <img src={Java} alt="Java" />
                    </div>
                    <div className="skills-item">
                        <img src={MySQL} alt="MySQL" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skills