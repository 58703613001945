import React from 'react'
import '../styles/Header.css'

const Header = () => {
    return (
        <div className="header">
            <div className="header-width">
                <div className="title">
                    <a href="#banner"><h1>DevFolio</h1></a>
                </div>
                <div className="nav">
                    <ul>
                        <li><a href="#projects">Projects</a></li>
                        <li><a href="#contact">Contact</a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Header