import React, { useState, useEffect, useRef } from 'react';
import '../styles/Contact.css';
import emailjs from 'emailjs-com'; // Import EmailJS SDK
import instagram from '../icons/instagram.png';
import linkedin from '../icons/linkedin.png';
import github from '../icons/github.png';
import gmail from '../icons/gmail.png';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    const aboutColumnRef = useRef(null);
    const contactColumnRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1, // Trigger animation when 10% of the element is in view
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (entry.target === aboutColumnRef.current) {
                        aboutColumnRef.current.classList.add('slide-in-left');
                    }
                    if (entry.target === contactColumnRef.current) {
                        contactColumnRef.current.classList.add('slide-in-right');
                    }
                }
            });
        }, options);

        const aboutColumn = aboutColumnRef.current; // Copy ref to local variable
        const contactColumn = contactColumnRef.current; // Copy ref to local variable

        if (aboutColumn) observer.observe(aboutColumn);
        if (contactColumn) observer.observe(contactColumn);

        return () => {
            if (aboutColumn) observer.unobserve(aboutColumn);
            if (contactColumn) observer.unobserve(contactColumn);
        };
    }, []);

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Send email using EmailJS
        emailjs
            .sendForm(
                'service_yc2ztgn',
                'template_9wxbble',
                e.target,
                'Al7EcHDVUjqw3I8UY'
            )
            .then(
                (result) => {
                    console.log('Message sent successfully:', result.text);
                    alert('Message sent successfully!');
                    setFormData({
                        name: '',
                        email: '',
                        subject: '',
                        message: '',
                    });
                },
                (error) => {
                    console.log('Error sending message:', error.text);
                    alert('Failed to send message. Please try again later.');
                }
            );
    };

    return (
        <div className="contact" id="contact">
            <div className="contact-width">
                <div className="contact-section">
                    <div className="about-column" ref={aboutColumnRef}>
                        <h3>About Me</h3>
                        <p>Hi, I’m Darren Tan Thong En, a passionate Software Engineer and Web Developer. With experience in both frontend and backend development,
                            I specialize in creating dynamic, user-friendly systems and applications.
                            My expertise ranges from building seamless and responsive websites to designing scalable solutions.
                        </p>
                        <p>I am proficient in a variety of technologies such as HTML, Css, JavaScript, React, Java, and more, allowing me to craft robust, full-stack solutions.
                            I focus on writing clean, maintainable code and ensuring the smooth functionality of every project I undertake.
                        </p>
                        <p>Whether working on user interfaces or developing backend systems, I am committed to building systems that not only meet the needs of users but also scale and perform well.
                            I am always learning, exploring new technologies, and striving to improve my craft.
                        </p>
                        <p>
                            Feel free to explore my work and get in touch if you're interested in collaborating on your next project!
                        </p>
                        <div className="social-icons">
                            <a href="https://www.instagram.com/darren_2581" target="_blank" rel="noopener noreferrer">
                                <img src={instagram} alt="Instagram" />
                            </a>
                            <a href="https://github.com/darren2581" target="_blank" rel="noopener noreferrer">
                                <img src={github} alt="GitHub" />
                            </a>
                            <a href="https://www.linkedin.com/in/darren2581" target="_blank" rel="noopener noreferrer">
                                <img src={linkedin} alt="LinkedIn" />
                            </a>
                            <a href="mailto:darrentanthongen@gmail.com" target="_blank" rel="noopener noreferrer">
                                <img src={gmail} alt="Gmail" />
                            </a>
                        </div>
                    </div>
                    <div className="contact-column" ref={contactColumnRef}>
                        <h3>Get In Touch</h3>
                        <div className="contact-form">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        placeholder="Your Name"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Your Email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="subject">Subject</label>
                                    <input
                                        type="text"
                                        id="subject"
                                        name="subject"
                                        placeholder="Subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Message</label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        placeholder="Your Message"
                                        value={formData.message}
                                        onChange={handleChange}
                                    ></textarea>
                                </div>
                                <button type="submit">Send Message</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;