import React from 'react';
import '../styles/Banner.css';

const Banner = () => {
    return (
        <div className="banner" id="banner">
            <div className="banner-width">
                <div className="banner-title">
                    <h2>Hello, <br />I'm Darren Tan</h2>
                    <h3>Web Developer / Software Engineer</h3>
                </div>
            </div>
        </div>
    );
};

export default Banner;
