import React from 'react'
import Banner from '../components/Banner';
import Contact from '../components/Contact';
import Experiences from '../components/Experiences';
import Header from '../components/Header';
import Project from '../components/Project';
import Skills from '../components/Skills';

const Portfolio = () => {
  return (
    <div>
        <Header />
        <Banner />
        <Skills />
        <Experiences />
        <Project />
        <Contact />
    </div>
  )
}

export default Portfolio