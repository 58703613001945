import React from 'react';
import '../styles/Project.css';
import InventorySystem from '../images/Project-InvSystem.png';
import NasaShedulingSystem from '../images/nasa-sheduling-system.png';
import ConnectFour from '../images/Connect-Four.png';
import FoodCalorieManagement from '../images/Food-Calorie-Management.png';
import Taskology from '../images/taskology.png';
import ToDoList from '../images/ToDoList.png';
import Anonymous from '../images/Anonymous.png';
import ChatApp from '../images/chatApp.png';

const projects = [
    {
        title: "Inventory Management System",
        description: "An intuitive web app for managing inventory, offering real-time updates. Users can upload pictures of items, automatically extracting their names, then adding descriptions and managing stock levels for efficient inventory control.",
        image: InventorySystem,
        tags: ["React", "CSS", "JavaScript", "Firebase", "Gemini API"],
        link: "https://inventory-management-system-eosin.vercel.app/"
    },
    {
        title: "Nasa Roadshow Scheduling System",
        description: "A web app where school teachers can book a slot for the NASA roadshow to visit their school on a specific date and time in different districts.",
        image: NasaShedulingSystem,
        tags: ["React", "CSS", "JavaScript", "Firebase"],
        link: "https://scheduling-system-iota.vercel.app/"
    },
    {
        title: "Task Management System Frontend",
        description: "Built the frontend of Taskology, a responsive and functional task management system that simplifies task tracking and organization",
        image: Taskology,
        tags: ["React", "CSS", "JavaScript"],
        link: "https://taskology-iota.vercel.app/"
    },
    {
        title: "Anonymous Chat Room",
        description: "Anonymous Chat Room allows users to interact and share thoughts anonymously.",
        image: Anonymous,
        tags: ["React", "CSS", "JavaScript", "AWS DynamoDB"],
        link: "https://collaborative-notes-two.vercel.app/"
    },
    {
        title: "To Do List",
        description: "An interactive To-Do List app built with React and AWS DynamoDB, offering real-time task management with a sleek design and seamless performance.",
        image: ToDoList,
        tags: ["React", "CSS", "JavaScript", "AWS DynamoDB"],
        link: "https://todo-list-app-teal-beta.vercel.app/"
    },
    {
        title: "Realtime Chat Web App",
        description: "Interactive real-time chat application with instant messaging and a sleek, user-friendly design.",
        image: ChatApp,
        tags: ["React", "CSS", "JavaScript", "Firebase"],
        link: "https://realtime-chat-app-liard-gamma.vercel.app/"
    },
    {
        title: "Connect Four Game Mobile App",
        description: "A simple and engaging Connect Four game app built for Android using Java and Kotlin.",
        image: ConnectFour,
        tags: ["Java", "Kotlin", "Android Studio"],
        link: "https://github.com/darren2581/Connect-Four-Game-Mobile-App"
    },
    {
        title: "Food Calorie Management Mobile App",
        description: "An intuitive mobile app for tracking food calories, allowing users to log meals and manage nutritional information.",
        image: FoodCalorieManagement,
        tags: ["Java", "Firebase", "CalorieNinjas API"],
        link: "https://github.com/darren2581/Food-Calorie-Management-Mobile-App"
    },
];

const Project = () => {
    return (
        <div className="project" id="projects">
            <div className="project-width">
                <div className="project-title">
                    <h2>Projects</h2>
                </div>
                <div className="project-content">
                    {projects.map((project) => (
                        <a href={project.link} target="_blank" rel="noopener noreferrer" key={project.id} className="project-card">
                            <div className="project-header">
                                <h3>{project.title}</h3>
                                <span className="material-symbols-outlined project-icon">
                                    arrow_outward
                                </span>
                            </div>

                            <div className="project-body">
                                <p>{project.description}</p>
                            </div>
                            <div className="project-image">
                                <img src={project.image} alt={project.title} />
                            </div>
                            <div className="project-footer">
                                <div className="project-tags">
                                    {project.tags.map((tag, index) => (
                                        <span key={index}>{tag}</span>
                                    ))}
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Project;